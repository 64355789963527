<template>
  <div>
    <nav-bar />
    <admin-page />
  </div>
</template>

<script>
import AdminPage from "../components/AdminPage.vue";
import NavBar from '../components/NavBar.vue';

export default {
  name: "Admin",

  components: {
    NavBar,
    AdminPage
  },
};
</script>

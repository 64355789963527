<template>
  <v-app id="inspire">
    <v-dialog v-model="loading" fullscreen>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.4)"
      >
        <v-layout justify-center align-center>
          <v-progress-circular
            indeterminate
            :size="60"
            :width="7"
            color="primary"
          >
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-container>
      <div class="pb-10 pl-8 grey--text text--darken-1 display-1">
        {{ $t("admin.users") }}
      </div>
    </v-container>
    <v-row justify="center">
      <v-dialog v-model="dialogNewPerson" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <!-- $t("meeting_minutes_form.create_and_insert") -->
            <span class="text-h5">{{ textForAddPersonSK }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    prepend-icon="mdi-account"
                    v-model="textName"
                    :label="$t('meeting_minutes_form.name')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    prepend-icon="mdi-at"
                    v-model="textEmail"
                    :label="$t('meeting_minutes_form.email')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    prepend-icon="mdi-cellphone"
                    v-model="textPhone"
                    :label="$t('meeting_minutes_form.phone')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    prepend-icon="mdi-cog-outline"
                    v-model="textPosition"
                    :label="$t('meeting_minutes_form.position')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-combobox
                    clearable
                    v-model="selectOvmAddUser"
                    prepend-icon="mdi-home"
                    :items="itemOvm"
                    :label="$t('meeting_minutes_form.organization')"
                  ></v-combobox>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeAddPerson()">
              {{ $t("meeting_minutes_form.close") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="addPerson()">
              {{ $t("meeting_minutes_form.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-container fluid fill-height v-if="roleOfActualPersonIsAdmin == true">
      <v-layout justify-center>
        <!-- align-center -->

        <v-flex xs12 sm8>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{ $t("admin.form") }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-alert v-show="errorAxios" class="mb-6" type="error">
                  {{ $t("meeting_table.error_connection") }}
                </v-alert>

                <v-row justify="center">
                  <v-dialog v-model="dialogError" persistent max-width="600px">
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{
                          $t("meeting_minutes_form.error_validation")
                        }}</span>
                      </v-card-title>
                      <v-card-text> </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialogError = false"
                        >
                          {{ $t("meeting_minutes_form.ok") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>

                <v-row>
                  <v-col cols="10">
                    <v-combobox
                      clearable
                      class="mt-6"
                      v-model="selectPerson"
                      @input.native="actualPerson = $event.srcElement.value"
                      prepend-icon="mdi-account"
                      :items="itemPersons"
                      :label="$t('admin.person')"
                      :rules="[requiredFieldPerson()]"
                      @change="actualPerson = selectPerson"
                      required
                    ></v-combobox>
                  </v-col>
                  <v-col cols="1" class="pt-10 text-right">
                    <v-btn
                      v-if="!!actualPerson && !this.isExistPerson(actualPerson)"
                      fab
                      color="primary"
                      @click="onChangePerson()"
                    >
                      <v-icon dark> mdi-folder-plus-outline </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="11">
                    <v-text-field
                      prepend-icon="mdi-at"
                      v-model="textConcreteEmail"
                      :rules="emailRules"
                      :label="$t('admin.email')"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="12" class="text-right">
                  <v-btn class="" color="primary mr-8" @click="submit()">{{
                    $t("admin.access_button")
                  }}</v-btn>
                </v-col>
                <v-col cols="12" md="12">
                  <p v-if="password.length > 0" class="ml-8">
                    {{ $t("registration.generate_password") }}
                    {{ password }}
                  </p>
                  <v-btn
                    v-if="password.length > 0"
                    color="primary mr-8"
                    class="ml-9"
                    @click="sentEmail()"
                  >
                    {{ $t("registration.send_email") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container fluid fill-height>
      <v-layout justify-center>
        <v-flex xs12 md8>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{ $t("admin.edit_user") }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <span class="text-caption"
                    >* vymazať používateľa je možne len priamo v databáze.
                  </span>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn
                    class=""
                    color="primary mr-8 mb-6"
                    @click="onChangePersonNew()"
                    >{{ $t("admin.add_user_button") }}</v-btn
                  >
                </v-col>
              </v-row>
              <v-card>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="headersAllPeoples"
                  :items="allPeoples"
                  :search="search"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          v-bind="attrs"
                          v-on="on"
                          fap
                          class="ma-0 mr-md-2"
                          @click="editItem(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>{{ $t("admin.editTooltip") }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
            <v-card-actions> </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>


<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    email: "",
    password: "",
    headersAllPeoples: [
      {
        text: "Meno",
        align: "start",
        value: "name",
      },
      { text: "Email", value: "email" },
      { text: "Mobil", value: "phone" },
      { text: "Pozícia", value: "position" },
      { text: "Ovm", value: "ovm.name" },
      { text: "Rola", value: "roleSK" },
      { text: "Akcie", value: "actions", sortable: false },
    ],
    roleOfActualPersonIsAdmin: false,
    allPeoples: [],
    search: "",
    id_user: "",
    id_token: "",
    idForPerson: "",
    itemOvm: [],
    persons: [],
    itemOvmObj: [],
    itemOvm: [],
    actualLoading: 0,
    valid: false,
    loading: false,
    dialogError: false,
    itemPersons: [],
    dialogNewPerson: false,
    errorAxios: false,
    textConcreteEmail: "",
    textForAddPersonSK: "",
    textEmail: "",
    textPosition: "",
    textPhone: "",
    actualPerson: "",
    selectOvmAddUser: "",
    selectPerson: "",
    textName: "",
    value: "",
    myPass: "",
    name: "Login",
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Nesprávny tvar",
    ],
  }),
  mounted() {
    this.actualLoading = 0;
    this.loadData();
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getToken", "getIsAdmin"]),
  },
  methods: {
    loadData() {
      if (this.getIsAdmin == "true") this.roleOfActualPersonIsAdmin = true;
      else this.roleOfActualPersonIsAdmin = false;
      this.id_token = this.getToken;
      this.setLoadingBlock();
      this.setLoadingBlock();

      this.loadPersons();
      this.loadOvm();

      // await this.loadPersons();
      // await this.loadOvm();
    },
    editItem(item) {
      this.textForAddPersonSK = "Editácia existujúceho účastníka";
      this.idForPerson = item.id;
      this.textName = item.name;
      this.textEmail = item.email;
      this.textPosition = item.position;
      this.textPhone = item.phone;
      this.selectOvmAddUser = item.ovm.name;
      this.dialogNewPerson = true;
      console.log("ss2s", this.dialogNewPerson);
    },
    loadPersons() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_PERSON, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.persons = response.data;
          this.allPeoples = response.data.filter((p) => {
            return p.exist == 1;
          });
          this.allPeoples.forEach((element) => {
            if (element.role && element.role.role)
              element.roleSK = element.role.role;
            if (!element.roleSK || element.roleSK == "") element.roleSK = "-";
            if (element.roleSK == "admin") element.roleSK = "Admin";
            if (element.roleSK == "user") element.roleSK = "Používateľ";
          });
          this.itemPersons = this.persons
            .filter((p) => {
              return p.exist == 1;
            })
            .map(function (p) {
              return p.name + ", " + p.position + ", " + p.ovm.name;
            });
          this.itemPersons.sort();

          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },

    loadOvm() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_OVM, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemOvmObj = response.data;
          this.itemOvm = [...new Set(this.itemOvmObj.map((item) => item.name))];
          this.completeLoadingBlock();
          this.itemOvm.sort();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    setLoadingBlock() {
      this.actualLoading = this.actualLoading + 1;
      this.loading = true;
    },
    completeLoadingBlock() {
      this.actualLoading = this.actualLoading - 1;
      if (this.actualLoading == 0) {
        this.loading = false;
      }
    },
    requiredFieldPerson() {
      let value = this.actualPerson;
      if (value) {
        if (!this.isExistPerson(value)) {
          return "Tento používateľ nie je v systéme, prosim vytvorte ho";
        } else {
          var element = this.persons.filter(
            (p) => p.name + ", " + p.position + ", " + p.ovm.name === value
          )[0];
          this.textConcreteEmail = element.email;
          // console.log(element)
          this.id_user = element.id;
          return true;
        }
      } else return "Toto pole je potrebné vyplniť";
    },
    isExistPerson(name) {
      if (this.itemPersons.indexOf(name) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    findOvmByName(name) {
      return this.itemOvmObj.filter((p) => p.name === name);
    },
    closeAddPerson() {
      this.textPhone = "";
      this.textPosition = "";
      this.selectOvmAddUser = "";
      this.textEmail = "";
      this.idForPerson = "";
      this.dialogNewPerson = false;
    },
    onChangePerson() {
      this.textForAddPersonSK = "Pridať nového účastníka";
      this.textName = this.actualPerson;
      if (!this.isExistPerson(this.actualPerson)) {
        this.dialogNewPerson = true;
      }
    },
    onChangePersonNew() {
      this.textForAddPersonSK = "Pridať nového účastníka";
      this.dialogNewPerson = true;
    },
    sentEmail() {
      let email = this.textConcreteEmail;
      let subject = "Prístupové údaje do aplikácie DXC Semper";
      let body =
        "Dobrý deň,%0A%0Abol Vám vytvorený prístupový účet do systému DXC Semper (https://semper.dxc-tech.sk).%0AVaše používateľské meno je Vaša emailová adresa, na ktorú bol tento email zaslaný." +
        " Heslo:%0A%0AHeslo: " +
        this.password +
        "%0A%0A" +
        "Pri prvom prihlásení budete vyzvaný na zmenu hesla. %0A %0AS pozdravom, %0ATím DXC Semper";
      window.location.href =
        "mailto:" + email + "?subject=" + subject + "&body=" + body;
    },
    axiosCatchPart(e) {
      if (e.name != "TypeError") {
        this.errorAxios = true;
      }
      if (e.response.status == 403) this.$router.push("login").catch(() => {});
      this.completeLoadingBlock();
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$refs.form.validate();
        if (this.selectPerson == "" || this.textConcreteEmail == "") {
          this.dialogError = true;
        } else {
          this.setLoadingBlock();

          let body = {
            personId: this.id_user,
            email: this.textConcreteEmail,
          };

          var optionAxios = {
            headers: {
              Authorization: `Bearer ${this.id_token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json; charset=utf8",
            },
          };
          axios
            .post(
              process.env.VUE_APP_URL + process.env.VUE_APP_REGISTRATION,
              body,
              optionAxios
            )
            .then((response) => {
              let newUser = response.data;
              this.email = newUser.email;
              this.password = newUser.password;
              this.completeLoadingBlock();
            })
            .catch((e) => {
              this.axiosCatchPart(e);
            });
        }
      }
    },
    addPerson() {
      let newPerson = "";
      if (this.selectOvmAddUser == "" || this.textName == "") {
        this.dialogError = true;
      } else {
        if (this.textEmail == "") this.textEmail = "-";
        if (this.textPosition == "") this.textPosition = "-";
        if (this.textPhone == "") this.textPhone = "-";
        this.setLoadingBlock();
        let ovm = this.findOvmByName(this.selectOvmAddUser);

        if (this.idForPerson == "") {
          let body = {
            ovm: { id: ovm[0].id, name: ovm[0].name, exist: ovm[0].exist },
            name: this.textName,
            email: this.textEmail,
            position: this.textPosition,
            phone: this.textPhone,
          };

          var optionAxios = {
            headers: {
              Authorization: `Bearer ${this.id_token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json; charset=utf8",
            },
          };
          axios
            .post(
              process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_PERSON,
              body,
              optionAxios
            )
            .then((response) => {
              newPerson = response.data;
              this.setLoadingBlock();
              this.loadPersons().then((x) => {
                let personTextFieldName =
                  newPerson.name +
                  ", " +
                  newPerson.position +
                  ", " +
                  newPerson.ovm.name;

                this.selectPerson = personTextFieldName;
                this.actualPerson = personTextFieldName;
                this.textConcreteEmail = this.textEmail;
                this.id_user = newPerson.id;
                this.dialogNewPerson = false;
                this.textName = "";
                this.textPosition = "";
                this.textPhone = "";
                this.selectOvmAddUser = "";
                this.textEmail = "";
                return x;
              });
              this.completeLoadingBlock();
            })
            .catch((e) => {
              this.axiosCatchPart(e);
            });
        } else {
          let body = {
            ovm: { id: ovm[0].id, name: ovm[0].name, exist: ovm[0].exist },
            name: this.textName,
            email: this.textEmail,
            position: this.textPosition,
            phone: this.textPhone,
          };

          var optionAxios = {
            headers: {
              Authorization: `Bearer ${this.id_token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json; charset=utf8",
            },
          };
          axios
            .patch(
              process.env.VUE_APP_URL +
                process.env.VUE_APP_URL_GET_PERSON +
                "/" +
                this.idForPerson,
              body,
              optionAxios
            )
            .then((response) => {
              this.setLoadingBlock();
              this.loadPersons().then((x) => {
                this.dialogNewPerson = false;
                this.textName = "";
                this.textPosition = "";
                this.textPhone = "";
                this.selectOvmAddUser = "";
                this.textEmail = "";
                this.idForPerson = "";
                return x;
              });
              this.completeLoadingBlock();
            })
            .catch((e) => {
              this.axiosCatchPart(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>